/*
Template Name: Chat App
Author: Themesbrand
Version: 0.0.3
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/accordion";
@import "custom/components/modals";
@import "custom/components/forms";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/emoji-picker";

// structure
@import "custom/structure/general";
@import "custom/structure/sidemenu";

// Pages
@import "custom/pages/chat-leftsidebar";
@import "custom/pages/user-chat";
@import "custom/pages/user-profile-details";
@import "custom/pages/authentication";



@media only screen and (max-width: 1366px) {
    .user-profile-sidebar {
        width: 365px !important;
    }
}

@media (min-width: 992px) { 
    .user-profile-sidebar {
        border-right: 4px solid $border-color;
        border-left: 0px;

    }
}

.ant-picker-footer .ant-picker-footer-extra {
    padding: 0px;
}

